<template>
  <div ref="surprise" class="surprise">
    <div class="top">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_packet_jd@2x.png"/>
    </div>
    <div class="bottom">
      <div class="image">
        <img src="https://cdn.cailu88.com/jingxuanshi/client_surprise_2@2x.png"
             v-if="channel !== 'weixin' && channel !== 'zfb'" @click="isAuth"/>
      </div>
      <div class="content">
        <div style="padding: 20px 26px">
          <p class="copy">{{ content }}</p>
          <van-button v-if="!status" type="primary" @click="isAuth('copy')">复制链接</van-button>
          <van-button v-else @click="copyText($event)">复制链接</van-button>
          <p class="text">
            <span>复制文案</span>
            <van-icon name="arrow"/>
            <span>好友下单</span>
            <van-icon name="arrow"/>
            <span>你赚佣金</span>
          </p>
        </div>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Icon, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'

const api = require('../../utils/api').api

Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)

export default {
  name: 'surprise',
  data () {
    return {
      isIOSApp: false,
      isAndroidApp: false,
      status: false,
      channel: '',
      token: '',
      version: '',
      url: '',
      copyContent: '',
      content: '京享红包越早抢越大，每天必抢三次！！！抢红包链接👉https://u.jd.com/tkkv48p',
      click: true,
    }
  },
  components: {
    Loading,
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    let u = window.navigator.userAgent
    this.isAndroidApp = (u.indexOf('Android') > -1) // android
    this.isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  mounted () {
    this.click = false
    this.$axios('/item/transfer', {
      params: {
        content: this.content,
        clientType: 2
      }
    }).then(res => {
      this.status = true
      this.copyContent = res.data.data.spreadContent
      this.click = true
    }).catch(err => {
      if (err.data.code == 'USER_UN_LOGIN') {
        this.status = false
      }
      this.click = true
    })
  },
  methods: {
    //复制
    copyText (event) {
      this.content = this.copyContent
      Toast.success({
        duration: 500, // 持续展示 toast
        message: '复制成功'
      })
      clipboard(this.copyContent, event)
    },

    // 进入会场
    jump () {
      let eventName = 'jumpJD'
      let paramObj = {
        url: this.url,
      }
      this.$h5AppLink(eventName, paramObj)
    },

    // 授权
    isAuth (type) {
      if (!this.click) {
        return false
      }
      if (this.url) {
        this.click = true
        setTimeout(() => {
          this.jump()
        })
        return
      }
      this.handleGetUrl(type)
    },

    // 获取url
    handleGetUrl (type) {
      this.$axios('/item/transfer', {
        params: {
          content: 'https://u.jd.com/tkkv48p',
          clientType: 2
        },
      }).then(res => {
        this.status = true
        this.url = res.data.data.spreadContent
        this.click = true
        if (type) {
          return false
        }
        setTimeout(() => {
          this.jump()
        })
      }).catch(err => {
        Toast.fail(err.data.message)
        this.click = true
        this.isLogin()
      })
    },

    // 登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/packet/jd`
      }
      this.$h5AppLink(event, param)
    },

  },
}
</script>
<style lang="less" scoped>
.surprise {
  min-height: 100vh;
  background: #D01A1A;

  .top {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;

    .image {
      margin-bottom: 11px;

      img {
        width: 275px;
      }
    }

    .content {
      margin: 0 auto;
      width: 340px;
      background: #FFFFFF;
      border-radius: 12px;

      div {
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      .copy {
        margin-bottom: 20px;
        line-height: 21px;
        text-align: center;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 3; //想显示多少行就给多大值
        //-webkit-box-orient: vertical;
      }

      .van-button {
        margin: 0 auto;
        width: 250px;
        height: 50px;
        background: #FB3337;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        border-radius: 25px;
        border: none;
      }

      .text {
        margin-top: 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #FB3337;
        line-height: 20px;

        .van-icon {
          margin: 0 10px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
