import { render, staticRenderFns } from "./jdPacket.vue?vue&type=template&id=27ed432c&scoped=true&"
import script from "./jdPacket.vue?vue&type=script&lang=js&"
export * from "./jdPacket.vue?vue&type=script&lang=js&"
import style0 from "./jdPacket.vue?vue&type=style&index=0&id=27ed432c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ed432c",
  null
  
)

export default component.exports